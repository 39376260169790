import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CircularProgress, TextField } from '@mui/material';
import Logo from "../images/logo.png"
import axios from 'axios';
function Header() {
    const [openQuoteDialog, setOpenQuoteDialog] = useState(false)
    const [formData, setFormData] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [show, setShow] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        let payload = {
            email: "nvdieselsolutions@gmail.com",
            quote: {
                email: formData["email"],
                mobile: formData["mobile"],
                name: formData["name"],
                message: formData["message"],
            }
        }
        axios.post("https://hrm-backend-node-44bm0apzq-maazz-06.vercel.app/hrm/api/v1/admin/sendGenericMail", payload).then(res => {
            console.log(res);
            if (res) {
                setOpenQuoteDialog(false)
                setFormData({})
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsSubmitting(false)
        })
    }
    return (
        <>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark header-bg">
                <NavLink to="/">
                    <a className="navbar-brand">
                        <center style={{ background: "#fff", borderRadius: "50px", padding: "0.15rem 0.5rem" }}>
                            <img src={Logo} className="" style={{ width: "7rem", height: "2.25rem" }} alt="logo" />
                        </center>
                        {/* <p>LOGO</p> */}
                    </a>
                </NavLink>
                <div>
                    <button
                        onClick={() => {
                            setShow(!show)
                        }}
                        className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div style={{ transition: "0.5s", opacity: show ? 1 : 0, height: show ? "10.5rem" : 0, position: "absolute", backgroundColor: "#222", width: "100vw", right: 0,padding:"0.5rem 0" }}>
                        <ul className="">
                            <li className="nav-item">
                                <p className="nlink" ><i className='fa fa-phone' /> <a href="tel:+919353918582" style={{ textDecoration: "none", color: "#fff" }}>+91 9353918582</a>
                                </p>
                            </li>
                            <li className="nav-item">
                                <p className="nlink" ><i className='fa fa-envelope-o' /> <a style={{ textDecoration: "none", color: "#fff" }} href="mailto:info@nvdieselsolutions.com">info@nvdieselsolutions.com</a></p>
                            </li>

                            <button className="btn my-2 my-sm-0" style={{ padding: "0.5rem 2rem", marginLeft: "1rem" }} type="button" onClick={() => {
                                setOpenQuoteDialog(true)
                            }}>
                                <NavLink className="nbtnlink" to="/">GET QUOTE</NavLink>
                            </button>
                        </ul>
                    </div>

                </div>


                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <NavLink to="/home">

                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <p className="nlink" ><i className='fa fa-phone' /> <a href="tel:+919353918582" style={{ textDecoration: "none", color: "#fff" }}>+91 9353918582</a>
                            </p>
                        </li>
                        <li className="nav-item">
                            <p className="nlink" ><i className='fa fa-envelope-o' /> <a style={{ textDecoration: "none", color: "#fff" }} href="mailto:info@nvdieselsolutions.com">info@nvdieselsolutions.com</a></p>
                        </li>
                    </ul>
                    <form
                        className="form-inline my-2 my-lg-0">
                        {/* <a className="headerappicon" href="http://rebrand.ly/tummoc" target="_blank">  <img src="/images/google-play.svg" style={{height: '2rem'}} /> </a> */}
                        {/* <a className="headerappicon" href="https://0x92l.app.link/mql2Jm1fqvb" target="_blank"><i class="fab fa-google-play"></i></a> */}
                        {/* <a className="headerappicon" href="https://0x92l.app.link/mql2Jm1fqvb" target="_blank"><i class="fab fa-apple"></i></a> */}

                        <button className="btn my-2 my-sm-0" style={{ padding: "0.5rem 2rem", marginRight: "2rem" }} type="button" onClick={() => {
                            setOpenQuoteDialog(true)
                        }}>
                            <NavLink className="nbtnlink" to="/">GET QUOTE</NavLink>
                        </button>

                    </form>
                </div>

            </nav>


            <Dialog
                open={openQuoteDialog}
                onClose={() => { setOpenQuoteDialog(false) }}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>
                    Quote
                </DialogTitle>

                <DialogContent>
                    <form className='row'
                        onSubmit={sendEmail}
                    >
                        <div style={{ marginBottom: "1rem" }} className='col-sm-12'>
                            <TextField value={formData["name"]} onChange={(e) => { setFormData((prevValues) => ({ ...prevValues, "name": e.target.value })) }} id="standard-basic" label="Name" required variant="standard" fullWidth />
                        </div>

                        <div className='col-sm-6'>
                            <TextField value={formData["email"]} onChange={(e) => { setFormData((prevValues) => ({ ...prevValues, "email": e.target.value })) }} id="standard-basic" type='email' label="Email" required variant="standard" fullWidth />
                        </div>

                        <div className='col-sm-6'>
                            <TextField value={formData["mobile"]} onChange={(e) => { setFormData((prevValues) => ({ ...prevValues, "mobile": e.target.value })) }} id="standard-basic" label="Mobile" required variant="standard" fullWidth />
                        </div>

                        <div style={{ margin: "1rem 0" }} className='col-sm-12'>
                            <TextField value={formData["message"]} onChange={(e) => { setFormData((prevValues) => ({ ...prevValues, "message": e.target.value })) }} multiline required variant="outlined" label="Message" fullWidth />
                        </div>

                        <div className='col-sm-12'>
                            <button disabled={isSubmitting} type='submit'
                                style={{ cursor: "pointer", width: "100%", backgroundColor: "#222", color: "#fff", padding: "0.5rem 0", border: "none", outline: "none" }}>
                                {isSubmitting ? <CircularProgress style={{ color: "#fff" }} size={20} /> : "Get An Instant Quote"}
                            </button>
                        </div>

                    </form>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default Header