import React, { useState } from 'react'
import "./hero.css"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CircularProgress, TextField } from '@mui/material';
import GreenJPEG from "../images/green_inside.jpeg"
import Generators from "../images/green_gen.png"
import Cummins from "../images/cummins_genset.png"
import Tata from "../images/tata_genset.png"
import Kirolskar from "../images/kirloskar_genset.jpeg"
import axios from 'axios';

function Hero() {
    const [openQuoteDialog, setOpenQuoteDialog] = useState(false)
    const [formData, setFormData] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [serviceModal, setServiceModal] = useState(false)
    const [serviceModalType, setServiceModalType] = useState()
    const sendEmail = (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        let payload = {
            email: "nvdieselsolutions@gmail.com",
            quote: {
                email: formData["email"],
                mobile: formData["mobile"],
                name: formData["name"],
                message: formData["message"],
            }
        }
        axios.post("https://hrm-backend-node-44bm0apzq-maazz-06.vercel.app/hrm/api/v1/admin/sendGenericMail", payload).then(res => {
            console.log(res);
            if (res) {
                setOpenQuoteDialog(false)
                setFormData({})
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsSubmitting(false)
        })
    }

    const gotodiv = () => {
        const element = document.querySelector('.welcome');
        element.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className='About_maindiv'>
            <div className="about_upper">
                <div className="about_upper_content">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="image">
                            <div className="overlayhero">
                                <h1>Powering Your World, Anytime, Anywhere.</h1>
                                <p style={{ margin: 0, textAlign: "center", color: "#fff" }}>We Provide Annual maintenance and Service of Genset in Bangalore</p>
                            </div>
                        </div>
                    </div>

                    <div className="arrowdiv_about">
                        <img
                            alt=''
                            src="https://d239pyg5al708u.cloudfront.net/uploads/icons/data_image_svg%2Bxml%3B%E2%80%A6_(1).svg"
                            className="arrow"
                            onClick={gotodiv}
                            style={{ width: "3rem", color: "white" }}
                        />
                    </div>
                </div>
                {/* end of upper content */}

                <div className='welcome'>
                    <h3 className='welcome_top'>👋 Hey there! Welcome to NV Diesel Solutions</h3>
                    <h4 className='quote'>
                        <q style={{}}><i>Stay Connected, Stay Powered with Us! </i></q>
                    </h4>
                    {/* <h4></h4> */}
                    <div className='welcome_low'>
                        <span >
                            We're thrilled you've dropped by to check out our lineup of gensets and top-notch services.
                            Whether you're hunting for reliable backup power or looking to superp genset services in Namma Bengaluru,
                            we've got you covered! Stick around, explore, and feel free to reach out if you've got any questions.
                        </span>


                    </div>
                </div>
                <div className="quote_div" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="get_qoute">
                        <div className="quote_overlayhero">
                            <h1>Get a Quote For Diesel Generator</h1>
                            <button className='btn ' onClick={() => {
                                setOpenQuoteDialog(true)
                            }}>Get Quote</button>
                            {/* <p style={{ margin: 0, textAlign: "center", color: "#fff" }}>We Provide Annual maintenance and Service of Genset in Bangalore</p> */}
                        </div>
                    </div>
                </div>
                <div className='services_div'>
                    <div>
                        <h4>Our Services</h4>
                        <div>
                            <ol>
                                <li>
                                    <b>Refurbishing, Installation, and Maintenance</b>
                                    <p className='left_space'>Comprehensive refurbishing services to breathe new life into your gensets.</p>
                                    <p className='left_space'>Expert installation and meticulous maintenance to ensure peak performance.</p>
                                </li>
                                <li>
                                    <b>Maintenance & Spare Parts Management</b>
                                    <p className='left_space'>Proactive maintenance to ensure seamless operations.</p>
                                    <p className='left_space'>Efficient spare parts management to minimize downtime.</p>
                                </li>
                                <li>
                                    <b>Performance Monitoring</b>
                                    <p className='left_space'>Real-time monitoring and logging of crucial parameters to keep your gensets running smoothly.</p>
                                    <p className='left_space'>Track temperature, motor current, cleaning pressure, oil temperature, and more.</p>
                                </li>
                                <li>
                                    <b>Maintenance Scheduling</b>
                                    <p className='left_space'>Customized schedules to meet your needs: weekly, monthly, quarterly, or annually.</p>
                                </li>
                                <li>
                                    <b>Shutdown Planning & Execution</b>
                                    <p className='left_space'>Strategic planning and flawless execution for hassle-free annual shutdowns.</p>
                                </li>
                                <li>
                                    <b>24/7 Emergency Services</b>
                                    <p className='left_space'>Immediate response and resolution during breakdowns.</p>
                                </li>
                            </ol>
                            {/* <ul>
                            <li>
                                Refurbishing, Installation, and Maintenance
                            </li>

                            <li>
                                Comprehensive refurbishing services to breathe new life into your gensets.
                            </li>

                        </ul>

                        Expert installation and meticulous maintenance to ensure peak performance.
                        Spare Parts Management

                        Effortless management of spare parts to minimize downtime and maximize efficiency.
                        Performance Monitoring

                        Real-time monitoring and logging of crucial parameters to keep your gensets running smoothly.
                        Track temperature, motor current, cleaning pressure, oil temperature, and more.
                        Maintenance Scheduling

                        Tailored maintenance schedules to suit your needs: weekly, monthly, quarterly, or annually.
                        Shutdown Planning and Execution

                        Strategic planning and flawless execution for hassle-free annual shutdowns.
                        Emergency Services

                        Round-the-clock emergency assistance to address breakdowns promptly and efficiently. */}
                        </div>
                    </div>

                    <div>
                        <img src={Generators} className='services_img' />
                    </div>
                </div>
                <div className="Lower_content">
                    <div
                        className="lower_content_data_new"
                        style={{ margin: "0px" }}>
                        <div className="image1" onClick={(e) => {
                            e.preventDefault()
                            setServiceModal(true)
                            setServiceModalType("amc")
                        }}>
                            <a href='' >
                                <a>
                                    <div className="overlayimage">
                                        <h1>Annual Maintenance & Contract Renewal of Genset </h1>
                                    </div>
                                </a>
                            </a>
                        </div>
                        <div className="image2"
                            onClick={(e) => {
                                e.preventDefault()
                                setServiceModal(true)
                                setServiceModalType("install")
                            }}
                        >
                            <a href=''>
                                <a>
                                    <div className="overlayimage">
                                        <h1>Genset Installation</h1>
                                    </div>
                                </a>
                            </a>
                        </div>
                    </div>
                    <div className="lower_content_data_new" style={{ margin: "0px" }}>
                        <div className="image3"
                            onClick={(e) => {
                                e.preventDefault()
                                setServiceModal(true)
                                setServiceModalType("rental")
                            }}
                        >
                            <a href=''>
                                <a>
                                    <div className="overlayimage">
                                        <h1>Genset Rental</h1>
                                    </div>
                                </a>
                            </a>
                        </div>
                        <div className="image4">
                            <a href=''>
                                <a>
                                    <div className="overlayimage">
                                        <h1>Buy/Sell Used Genset</h1>
                                    </div>
                                </a>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="supply_new">
                    <h4 style={{ borderBottom: "1px solid #ddd", paddingBottom: "0.5rem" }}>We Supply New Gensets</h4>
                    <div className='supply_flex'>
                        <center className='supply_new_div'>
                            <img src={Cummins} className='cummins_img' />
                            <p>Cummins Genset</p>
                        </center>

                        <center className='supply_new_div'>
                            <img src={Tata} className='tata_img' />
                            <p>Tata Genset</p>
                        </center>

                        <center className='supply_new_div'>
                            <img src={Kirolskar} className='kirolskar_img' />
                            <p>Kirolskar Genset</p>
                        </center>
                    </div>
                </div>
            </div>
            <Dialog
                open={openQuoteDialog}
                onClose={() => { setOpenQuoteDialog(false) }}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>
                    Quote
                </DialogTitle>

                <DialogContent>
                    <form className='row'
                        onSubmit={sendEmail}
                    >
                        <div style={{ marginBottom: "1rem" }} className='col-sm-12'>
                            <TextField value={formData["name"]} onChange={(e) => { setFormData((prevValues) => ({ ...prevValues, "name": e.target.value })) }} id="standard-basic" label="Name" required variant="standard" fullWidth />
                        </div>

                        <div className='col-sm-6'>
                            <TextField value={formData["email"]} onChange={(e) => { setFormData((prevValues) => ({ ...prevValues, "email": e.target.value })) }} id="standard-basic" type='email' label="Email" required variant="standard" fullWidth />
                        </div>

                        <div className='col-sm-6'>
                            <TextField value={formData["mobile"]} onChange={(e) => { setFormData((prevValues) => ({ ...prevValues, "mobile": e.target.value })) }} id="standard-basic" label="Mobile" required variant="standard" fullWidth />
                        </div>

                        <div style={{ margin: "1rem 0" }} className='col-sm-12'>
                            <TextField value={formData["message"]} onChange={(e) => { setFormData((prevValues) => ({ ...prevValues, "message": e.target.value })) }} multiline required variant="outlined" label="Message" fullWidth />
                        </div>

                        <div className='col-sm-12'>
                            <button disabled={isSubmitting} type='submit'
                                style={{ cursor: "pointer", width: "100%", backgroundColor: "#222", color: "#fff", padding: "0.5rem 0", border: "none", outline: "none" }}>
                                {isSubmitting ? <CircularProgress style={{ color: "#fff" }} size={20} /> : "Get An Instant Quote"}
                            </button>
                        </div>

                    </form>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>


            <Dialog
                open={serviceModal}
                onClose={() => {
                    setServiceModal(false)
                    setServiceModalType()
                }}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle>
                    Scope Of Work
                </DialogTitle>

                <DialogContent>
                    {serviceModalType == "amc" ?
                        <>
                            1. Cleaning of the Air filter.<br />
                            2. Checking and adjusting of valve tappet clearance.<br />
                            3. Checking of Engine lubrication system.<br />
                            4. Change of lube oil filter if necessary.<br />
                            5. Checking and tightening of bolt and nuts.<br />
                            6. Checking of flexible rubber coupling.<br />
                            7. Checking of the charging system of the battery.<br />
                            8. Checking of ‘V’ belt and adjusting the tension.<br />
                            9. Checking the exhaust turbo charger.<br />
                            10. Checking of fuel injector.<br />
                            11. Cleaning of the breather pipe and refilling the engine oil in the injector pump.<br />
                            12. Checking of alternator terminal, cables tightness.<br />
                            13. Safety controls operations checking.<br />
                            14. Preventive Maintenance Checks as per manufacturer recommendation.<br />
                        </> : serviceModalType == "install" ? <>
                            1. Exhaust Pipe Errection Work<br />
                            2. Aluminum Cladding Service<br />
                            3. Cable Laying and Termination<br />
                            4. Bescom Approval<br />
                        </> : serviceModalType == "rental" ? <>
                            1. Truck Mounted Diesel Generators<br />
                            2. Stationary Diesel Generators<br />
                        </> : <></>
                    }
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Hero