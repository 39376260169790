import React from 'react'
import Header from '../components/Header'
import Hero from '../components/Hero'

function Home() {
  return (
    <div>
      <Header />
      <Hero/>
      {/* <section className='hero'>
        <div className=''>
          <div className='taglines'>

          </div>
          <div className='pictures'>

          </div>
        </div>
        <div>

        </div>
      </section> */}
    </div>
  )
}

export default Home